html, body, #root {
  font-size: 1.2rem;
  font-family: georgia, 'times new roman', times, serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.annotation-panel-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100vh;
}

.category-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 4rem 1rem;
}

.category-card {
  text-decoration: none;
  color: #333;
  padding: 1rem;
  background-color: #fff;
  border-radius: .5rem;
  margin: .5rem;
}

.category-card:hover {
  background: rgba(0, 0, 0, .1);
}

.article-container {
  overflow-y: scroll;
  padding-bottom: 4rem;
  scroll-behavior: smooth;
  background-color: #fff;
}

.not-so-smooth {
  scroll-behavior: unset;
}

.article-container>* {
  padding-left: 2rem;
  padding-right: 2rem;
}

.article-container>p>span>span {
  cursor: pointer;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.article-container>p>span>span:hover {
  border-bottom: 2px dashed #000;
}

.legends {
  margin-top: 1.75rem;
  position: sticky;
  top: 0;
  padding: .25rem 2rem;
  background: #fff;
}

.legends span {
  display: inline-block;
  margin: .25rem 0;
  line-height: 1.1;
  cursor: pointer;
}

.legends span:first-child {
  text-decoration: underline;
  cursor: pointer;
  transition: all .25s;
}

.legends span:first-child:hover {
  background-color: #eee;
}

.legends span:not(:last-child) {
  margin-right: .75rem;
}

.legends span.deselected {
  background: transparent;
  text-decoration: stroke;
}

.annotator-container {
  background-color: rgba(245, 245, 220, .5);
  padding: 2rem 1rem 4rem;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.annotator-container p, .annotator-container h3 {
  padding: .6rem 1rem;
  margin: 0;
}

.explanation {
  padding: 1rem !important;
  background-color: #fff;
  border-radius: .5rem;
  transition: all .25s;
  overflow: hidden;
}

.sentence-card {
  display: grid;
  grid-template-columns: auto 1.5rem;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: .75rem;
  border-radius: .5rem;
  transition: all .25s;
  overflow: hidden;
  position: relative;
}

.sentence-card-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: .5rem;
}

.sentence-card p {
  user-select: none;
}

.sentence-card:hover, .explanation:hover {
  box-shadow: 0 0 1rem rgba(32, 32, 32, .1);
}

.sentence-card .side {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, .1);
  justify-content: space-between;
  color: #333;
  transition: all .25s;
}

.sentence-card.checked {
  background-color: rgba(3, 166, 120, .2);
}

.sentence-card.checked .side {
  color: #fff;
  background-color: rgba(3, 166, 120, .8);
}

.sentence-card .side::after {
  opacity: 0;
  visibility: hidden;
  transition: all .25s;
  color: #fff;
  content: '✓';
}

.sentence-card.checked .side::after {
  opacity: 1;
  visibility: visible;
}

.last-next-buttons {
  display: flex;
  position: fixed;
  width: 50vw;
  bottom: 0;
  right: 0;
  justify-content: space-between;
  background: rgb(243, 230, 185);
}

.last-article-button, .next-article-button {
  cursor: pointer;
  background-color: rgb(244, 208, 63);
  font-size: 1rem;
  font-family: Helvetica, Arial, Sans-Serif;
  transition: all .25s;
  padding: .5rem 1rem;
}

.last-article-button:hover, .next-article-button:hover {
  box-shadow: 0 0 1rem rgba(32, 32, 32, .1);
}

.last-article-button {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.next-article-button {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.article-card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  font-size: .8rem;
  line-height: 1.2;
  margin: .5rem 0 .5rem 0;
  position: relative;
}

.article-card-container .article-container {
  font-size: .2rem;
  height: 15rem;
  width: 11rem;
  overflow: hidden;
  padding: .5rem;
  border-radius: .5rem;
  transition: all .25s;
}

.article-card-container:hover {
  cursor: pointer;
}

.article-card-container:hover .article-container {
  box-shadow: 0 0 1rem rgba(32, 32, 32, .2);
}

.article-card-container .article-container-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 16rem;
}

.article-card-container span {
  margin-top: .25rem;
}

.article-card-container .article-container>* {
  padding: 0;
}

.article-card-panel-container {
  padding: 16px 16px 48px;
}

.article-card-panel-container > div {
  overflow: visible !important;
}

.username-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.username-input {
  width: 15rem;
  height: 1.85rem;
  font-size: 1.2rem;
  margin-right: .5rem;
  padding: 0 .5rem;
  border-radius: 1rem;
  outline: 0;
  color: #333;
  background-color: #fff;
  border: none;
}

.username-button {
  background-color: rgb(250, 216, 89);
  font-size: 1rem;
  padding: .1rem 1rem;
  border: solid 2px rgb(240, 240, 214);
  border-radius: 2rem;
  color: #333;
  cursor: pointer;
}

@keyframes shakey-sentence-motion {
  0% { transform: translateX(0) }
  20% { transform: translateX(1.5rem) }
  40% { transform: translateX(-1rem) }
  60% { transform: translateX(.5rem) }
  80% { transoform: translateX(-.25rem) }
  100% { transform: translateX(0) }
}

.shakey-sentence {
  animation-name: shakey-sentence-motion;
  animation-duration: .75s;
}

@keyframes selected-sentence-highlight {
  0% { outline: rgba(241, 130, 141, .8) solid 6px }
  50% { outline: rgba(240, 52, 52, .8) solid 6px }
  100% { outline: rgba(240, 52, 52, 0) solid 6px }
}

.selected-sentence {
  animation-name: selected-sentence-highlight;
  animation-duration: 1.5s;
}

.hotkey-selected-sentence {
  outline: rgba(240, 52, 52, .8) solid 6px;
}

@keyframes selected-sentence-card-highlight {
  0% { box-shadow: 0 0 1rem rgba(3, 166, 120, 0) }
  25% { box-shadow: 0 0 1rem rgba(3, 166, 120, .8) }
  50% { box-shadow: 0 0 1rem rgba(3, 166, 120, .8) }
  100% { box-shadow: 0 0 1rem rgba(3, 166, 120, 0) }
}

.selected-sentence-card {
  animation-name: selected-sentence-card-highlight;
  animation-duration: 1.5s;
}
